/* Primary Palette */
$color-primary: #e6536d;
$color-primary-d15: #f2a6b3;
$color-primary-l20: #81c253;
$color-primary-l80: #dfefd4;
$color-ghost-white: #f6f8fe;

$color-black: #212121;
$color-black-l20: #4d4d4d;
$color-black-l80: #d2d2d2;

$color-black-d15: #{mix(#212121, #000, 85%)};
$color-black-l80: #{mix(#212121, #fff, 20%)};
$color-black-l95: #{mix(#212121, #fff, 5%)};

$color-gunmetal: #2e2d4a;
$color-gunmetal-d20: #24243b;

/* Secondary Palette */
$color-red: #cc3427;
$color-red-l20: #dc574b;

$color-red-d15: #{mix(#cc3427, #000, 85%)};
$color-red-l80: #{mix(#cc3427, #fff, 20%)};
$color-red-l95: #{mix(#cc3427, #fff, 5%)};

$color-grey: #bbc0d1;
$color-grey-l20: #c8ccda;
$color-grey-l80: #4d4d4d;
$color-grey-l95: #fbfbfc;

$color-grey-d15: #{mix(#bbc0d1, #000, 85%)};
$color-grey-d20: #{mix(#bbc0d1, #000, 80%)};
$color-grey-l80: #{mix(#bbc0d1, #fff, 20%)};
$color-grey-l95: #{mix(#bbc0d1, #fff, 5%)};

$color-yellow: #f9cc59;
$color-blue: #9cb7fe;
$color-blue-l80: #{mix(#9cb7fe, #fff, 20%)};
$color-blue-semantic: #0056d7;

$color-highlight: #0078d4;
$color-highlight-d15: #{mix(#0078d4, #000, 85%)};
$color-highlight-l80: #{mix(#0078d4, #fff, 20%)};
$color-highlight-l95: #{mix(#0078d4, #fff, 5%)};
$color-highlight-2: #2c98ff;

$drop-shadow: 1px 2px 6px rgba($color-black, 0.2) !default;
$shadow-alt: 0 10px 16px 0 rgba(0, 0, 0, 0.18);

/* Semantic color */
$success: #065f46;
$success-secondary: #4a9631;
$info: #1e40af;
$warning: #92400e;
$warning-secondary: #f59e0b;
$danger: #b91c1c;
$semantic-red: #d03939;

/*Background semantic color*/
$bg-success: #d1fae5;
$bg-success-secondary: #dbf0cb;
$bg-info: #dbeafe;
$bg-warning: #fef3c7;
$bg-danger: #fee2e2;
$bg-monochrome: #9e9e9e;
$color-green-semantic: #3a9a4a;
$color-orange-semantic: #fcbb45;

$color-platinum: #e6e6e6;
$color-card: #faf8fc;

:root {
  --max-container-width: 1850px;
  --color-text: #0d0d0f;
  /* Spacing */
  --sp-1: 2px;
  --sp-2: 4px;
  --sp-3: 8px;
  --sp-4: 12px;
  --sp-5: 16px;
  --sp-6: 24px;
  --sp-7: 32px;
  --sp-8: 40px;
  --sp-9: 48px;
  --sp-10: 64px;
  --sp-11: 96px;
  --sp-12: 160px;
}

$padding-root: 5vw;

$color-ui-bg: #f3f6f8;
$color-ui-elements: #d8dee4;
$color-main: #252b40;
$color-placeholder: #80838d;

$font-family-1: 'Jost';
$font-family-2: 'Jost';

$color-neutral-1: #0d0d0f;
$color-neutral-2: #737373;
$color-neutral-3: #b3b3b3;
$color-neutral-5: #8c8c8c;
$color-neutral-6: #262626;
$color-black: #0f111e;
$color-green: #4b9f87;
$color-white: #ffffff;
$accent-2: #e9f3f0;

$breakpoint-sm: 576px;
$breakpoint-md: 768px;
$breakpoint-lg: 992px;
$breakpoint-xl: 1200px;
$breakpoint-xxl: 1600px;

$default-size: 28px;
$label-font-size-redo: calc($default-size * 4);
$label-font-size: calc($default-size / 4);