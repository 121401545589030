@import '../../common/sass/variables';

.avatar {
  &__image {
    width: 48px;
    height: 48px;
    position: relative;
    object-fit: cover;
  }

  &--xs {
    width: 24px;
    height: 24px;
  }
  &--sm {
    width: 36px;
    height: 36px;
  }
  &--md {
    width: 48px;
    height: 48px;
  }
  &--lg {
    width: 64px;
    height: 64px;
  }

  &--rounded {
    border-radius: 50%;
  }

  &--border {
    border-radius: 50%;
    border: 2px solid $color-white;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
  }

  &__red-dot {
    background-color: $color-primary;
    width: 14px;
    height: 14px;
    border-radius: 50%;
    position: absolute;
    top: 8px;
    left: 35px;
  }
}
