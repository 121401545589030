.modal {
  &-overlay {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1040;
    width: 100vw;
    height: 100vh;
    background-color: #000;
    opacity: 0.2;
  }

  &-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1050;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    outline: 0;
    display: flex;
    justify-content: center;
    align-items: center;

    &--left {
      justify-content: left;
    }

    &--right {
      justify-content: right;
    }

    &--top {
      align-items: flex-start;
    }

    &--bottom {
      align-items: flex-end;
    }
  }
}
