@import '../../common/sass/variables';

.icon {
  width: 16px;
  height: 16px;
  box-sizing: content-box;
  fill: $color-ghost-white;
  pointer-events: none;
  transform: scale(1);
  flex-shrink: 0;
  
  &--rotate-45 {
    transform: rotate(45deg);
  }
  &--rotate-90 {
    transform: rotate(90deg);
  }
  &--rotate-180 {
    transform: rotate(180deg);
  }
  &--xs {
    width: 12px;
    height: 12px;
  }
  &--sm {
    width: 16px;
    height: 16px;
  }
  &--md {
    width: 24px;
    height: 24px;
  }
  &--lg {
    width: 32px;
    height: 32px;
  }
}
