@import '../../common/sass/variables';

.sign-on-bonus-banner {
  font-family: $font-family-1;
  padding: 16px 20px;
  background-color: #e4566d;
  color: #fee9cc;
  position: fixed;
  top: 0;
  width: 100%;
  text-align: center;
  z-index: 10;

  &__link {
    color: #fee9cc;
    font-weight: 700;
    text-decoration: underline;
  }
}
