@import '../../../../common/sass/variables';

.user-dropdown {
  $root: &;
  position: relative;
  display: flex;
  justify-content: center;

  &__wrapper {
    &--show {
      #{$root}__content {
        display: block;
        overflow: hidden;
      }
    }
  }

  &__img {
    cursor: pointer;
    height: 36px;
  }

  &__content {
    display: none;
    position: absolute;
    right: 0px;
    top: 70px;
    min-width: 160px;
    background: #ffffff;
    box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.1);
    border-radius: 16px;
    z-index: 1;
    width: 258px;

    a {
      color: black;
      text-decoration: none;
      display: block;
    }
  }

  &__item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 16px;
    width: 258px;
    height: 48px;
    border-bottom: 1px solid #e6e6e6;
    position: relative;

    &:hover {
      background-color: #f1f1f1;
    }

    &-icon {
      width: '100%';
      height: '100%';
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      color: currentColor;
      width: 20px;
      height: 20px;
    }

    &-content {
      padding-left: 35px;
      padding-right: 15px;
      font-family: $font-family-1;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: $color-neutral-1;
    }

    &-badge {
      background-color: $color-primary;
      color: $color-white;
      width: 24px;
      height: 24px;
      border-radius: 50%;
      font-size: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &--red-dot {
      background-color: $color-primary;
      width: 14px;
      height: 14px;
      border-radius: 50%;
    }

    .txt-logout {
      color: #ff0000;
    }

    &-logout {
      border-bottom: 0;
    }
  }

  &__badge {
    background-color: $color-primary;
    width: 14px;
    height: 14px;
    border-radius: 40px;
  }
}
