@import '../../common/sass/variables';

.admin {
  display: flex;
  margin: auto;
  width: calc(100vw - 80px);
  gap: 8px;
  min-height: 90vh;
  &_nav {
    max-width: 250px;
    width: 15%;
  }

  &_main {
    width: 85%;

    &-back {
      cursor: pointer;

      display: flex;
      gap: 16px;
      align-items: center;
      margin-left: 30px;

      width: 100px;

      margin-top: 8px;
      margin-bottom: 16px;
    }

    .campaign-info__card {
      padding: var(--sp-4);
    }
    > .campaign-detail {
      padding-top: 0px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      gap: 8px;
      width: 92%;

      &__right-content {
        display: flex;
        flex-direction: column;
      }

      &__notification {
        padding-bottom: var(--sp-6);
      }

      .campaign-info__back-btn {
        display: none;
      }

      @media only screen and (max-width: $breakpoint-xl) {
        flex-direction: column-reverse;

        &__right-content {
          display: flex;
          flex-direction: column-reverse;
        }

        &__timer {
          padding-bottom: var(--sp-6);
        }
      }
    }
  }
  .custom-pagination {
    justify-content: center;
  }

  .brandprofile_campaigns {
    width: auto;
  }
  .cursor-pointer {
    cursor: pointer;
  }
}

@media only screen and (max-width: $breakpoint-lg) {
  .admin {
    width: 100vw;
  }
}
