@import './common/sass/variables';
@import './common/sass/font.scss';
@import '~react-toastify/dist/ReactToastify.css';
@import url('https://fonts.googleapis.com/css2?family=Jost:wght@100;200;300;400;600;700&display=swap');
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -ms-overflow-style: none;
}

::-webkit-scrollbar {
  display: none;
}

html {
  margin: 0;
  padding: 0;
  font-family: $font-family-1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: url('../src/common/assets/images/background.png');
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  -webkit-background-size: 100%;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-attachment: fixed;
  font-size: 16px;
}
.container {
  overflow-x: hidden;
}
a {
  text-decoration: none;
}

.ReactModal__Body--open {
  overflow-y: hidden;
}
