@import '../../../common/sass/variables';

.navbar_adm {
  overflow: hidden;
  position: sticky;
  top: 100px;
  &__link {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 16px;
    max-width: 250px;
    height: 48px;
    position: relative;

    &-icon {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      color: currentColor;
      width: 20px;
      height: 20px;
    }

    &-title {
      padding-left: 35px;
      padding-right: 15px;
      font-family: $font-family-1;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: $color-neutral-1;
    }
  }
  .menu-active {
    background: rgba(255, 255, 255);
    box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.1);
    border-radius: 16px;
  }
}
