@import '../../../common/sass/variables';

.default-layout {
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  &__content {
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 100px;
  }

  &__navbar {
    top: 50px;
  }
}

@media only screen and (max-width: $breakpoint-md) {
  .default-layout {
    &__navbar {
      top: 70px;
    }
  }
}
