/* Proxima Nova */
@font-face {
  font-family: 'Proxima Nova';
  src: url('../assets/fonts/ProximaNova-Regular.otf') format('truetype');
  font-weight: normal;
}
@font-face {
  font-family: 'Proxima Nova';
  src: url('../assets/fonts/ProximaNova-Thin.otf') format('truetype');
  font-weight: 100;
}
@font-face {
  font-family: 'Proxima Nova';
  src: url('../assets/fonts/ProximaNova-Thin.otf') format('truetype');
  font-weight: 200;
}
@font-face {
  font-family: 'Proxima Nova';
  src: url('../assets/fonts/ProximaNova-Light.otf') format('truetype');
  font-weight: 300;
}
@font-face {
  font-family: 'Proxima Nova';
  src: url('../assets/fonts/ProximaNova-Medium.otf') format('truetype');
  font-weight: 500;
}
@font-face {
  font-family: 'Proxima Nova';
  src: url('../assets/fonts/ProximaNova-SemiBold.otf') format('truetype');
  font-weight: 600;
}
@font-face {
  font-family: 'Proxima Nova';
  src: url('../assets/fonts/ProximaNova-Bold.otf') format('truetype');
  font-weight: 700;
}
@font-face {
  font-family: 'Proxima Nova';
  src: url('../assets/fonts/ProximaNova-ExtraBold.otf') format('truetype');
  font-weight: 800;
}
@font-face {
  font-family: 'Proxima Nova';
  src: url('../assets/fonts/ProximaNova-Black.otf') format('truetype');
  font-weight: 900;
}

// TT Hazelnuts
@font-face {
  font-family: 'TT Hazelnuts';
  src: url('../assets/fonts/TTHazelnuts-Regular.woff') format('truetype');
  font-weight: normal;
}
@font-face {
  font-family: 'TT Hazelnuts';
  src: url('../assets/fonts/TTHazelnuts-Bold.woff') format('truetype');
  font-weight: 700;
}