@import '../../common/sass/variables';

.button {
  $root: &;
  cursor: pointer;
  height: 52px;

  &--default {
    background: $color-primary;
    border: 1px solid $color-primary;
    color: $color-white;
    min-width: 80px;
    border-radius: 40px;
    padding: var(--sp-4);
    transition: all 0.3s ease-in-out;
    font-weight: bold;

    &:hover {
      background: $color-primary-d15;
      border: 1px solid $color-primary-d15;
    }

    &:focus {
      box-shadow: $drop-shadow;
    }

    &[disabled] {
      background: $color-neutral-3;
      border: 1px solid $color-neutral-3;
      color: $color-neutral-2;
    }
  }

  &--primary {
    border-radius: 40px;
    padding: 12px 24px;
    border: 0;
    background-color: $color-black;
    &:disabled {
      background-color: $color-neutral-3;
      cursor: default;
    }
  }
  &--outline {
    border-radius: 40px;
    height: 52px;
    border: solid 1.5px $color-neutral-2;
    background-color: transparent;
  }
  &--icon {
    display: flex;
    align-items: center;
  }
  &__title {
    font-size: 18px;
    line-height: 28px;
    font-family: $font-family-1;
    font-style: normal;
    text-align: center;
    font-weight: 600;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  &__title--primary {
    color: $color-white;
  }
  &__title--outline {
    color: $color-neutral-1;
  }

  &--red-dot {
    width: 8px;
    height: 8px;
    border-radius: 4px;
    background-color: #D03939;
    margin-left: 10px;
  }
}
