@import '../../../common/sass/variables';

.only-navbar-layout {
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  &__content {
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 122px;
  }
}
