@import '../../common/sass/variables';

.navbar {
  display: flex;
  flex-direction: row;
  top: 0;
  width: 100%;
  padding: 34px $padding-root;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  z-index: 10;

  &__bg {
    background: url('../../../src/common/assets/images/background.png');
    background-size: 100%;
    height: 100px;
  }

  &__menu--show {
    right: 0px !important;
  }

  &__scale {
    height: 300px;
    align-items: flex-start;
  }

  &__logo {
    cursor: pointer;
    margin-right: 1em;

    img {
      width: 118px;
      height: 48px;
    }
  }

  &__language {
    position: absolute;
    right: 20px;
    cursor: pointer;
    padding: 4px 5px;
    height: 30px;
    width: 40px;
    color: $color-black-l20;
  }
  &__icon {
    width: 20px;
    height: 20px;
    border-radius: 50%;
  }

  &__menu {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &__language {
      display: none;

      &-icon {
        padding: 4px 5px;
        height: 30px;
        width: 40px;
        color: $color-black-l20;
      }
    }
    .influencer-btn {
      margin-right: 1.5em;
    }

    &--register {
      width: 200px;
      padding: 0px;
    }

    &--link {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &-button {
      min-width: 200px;
    }

    &-profile-btn {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      padding-left: 10px;
      padding-right: 10px;
    }

    .profile-btn {
      min-width: 150px;
    }

    &-profile-name {
      font-family: $font-family-1;
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 28px;
      color: $color-neutral-1;
      max-width: 150px;
      padding-left: 10px;
      padding-right: 10px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }

    a {
      text-decoration: none;
      font-size: 1.25em;
      color: $color-neutral-1;
      font-family: $font-family-1;
      font-style: normal;
      font-weight: 600;
      line-height: 28px;
      cursor: pointer;
      margin-right: 2.5em;
    }

    .menu-active {
      color: $color-primary;
    }
  }

  &__burger {
    display: none;
    margin-left: 1em;
    cursor: pointer;

    &--toggle {
      cursor: pointer;
      height: 30px;
      width: 30px;

      .ico,
      .ico:before,
      .ico:after {
        cursor: pointer;
        border-radius: 1px;
        height: 5px;
        width: 30px;
        background: #000;
        position: absolute;
        right: inherit;
        display: block;
        content: '';
        transition: all 300ms ease-in-out;
      }

      .ico {
        position: relative;

        &:before {
          top: 20px;
        }

        &:after {
          bottom: -10px;
        }
      }

      &.burger--active {
        position: relative;

        .ico:before,
        .ico:after {
          top: 0;
        }

        .ico {
          background-color: transparent;

          &:before {
            transform: rotate(-45deg);
            top: 15px;
          }

          &:after {
            transform: rotate(45deg);
            top: 15px;
          }
        }
      }
    }
  }

  &__profile {
    display: none;
  }

  &_logout {
    cursor: pointer;
    font-size: 20px;
    &-item {
      display: flex;
      gap: 8px;
      font-weight: 600;
    }
  }
}
@media only screen and (max-width: $breakpoint-xl) {
  .navbar {
    &__language {
      position: absolute;
      right: 12px;
      cursor: pointer;
      img {
        width: 30px;
        height: 30px;
      }
    }
  }
}

@media only screen and (max-width: $breakpoint-lg) {
  .navbar {
    &__menu {
      &__language {
        display: flex;
        align-items: center;
        cursor: pointer;
        font-size: 1.25em;
        font-weight: 600;
        gap: 8px;
        img {
          width: 30px;
          height: 30px;
          margin-left: 8px;
        }
      }

      &--link {
        width: 100%;
        right: -100%;
        top: 86px;
        transition: 0.3s;
        position: absolute;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        padding: 16px;

        a {
          padding-bottom: 14px;
          padding-top: 14px;
          margin-right: 0px;
          width: 100%;
          text-align: right;
        }
      }
    }

    &__language {
      display: none;
    }
    &__burger {
      display: block;
      position: relative;
    }

    &__profile {
      display: block;
    }
  }
}

@media only screen and (max-width: $breakpoint-md) {
  .navbar {
    &__menu {
      &--register {
        display: none;
      }
    }
    &__language {
      display: none;
    }
  }
}

@media only screen and (max-width: $breakpoint-sm) {
  .navbar {
    &__language {
      display: none;
    }

    &__menu {
      &-button {
        min-width: 0;
      }

      &-profile-btn {
        width: 75px;
      }

      &-profile-name {
        display: none;
      }
    }
  }
}

.en-btn > p {
  font-size: 18px;
}
.th-btn > p {
  font-size: 14px;
}
