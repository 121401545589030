@import '../../common/sass/variables';

.footer {
  padding: 50px 3vw;
  background-color: $color-black;
  color: $color-white;

  &__logo {
    display: flex;
    align-items: center;
    flex: 1;
  }

  &__logo__image {
    width: 100px;
    height: 40px;
  }

  &__logo__mail {
    margin-left: 40px;
    font-family: $font-family-1;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
  }

  nav {
    padding-bottom: 48px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #383B43;

    .footer__link {
      flex: 1;
      display: flex;
      gap: 32px;
      justify-content: flex-end;

      a {
        font-family: $font-family-1;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        cursor: pointer;
        color: $color-white;
      }
    }

  }

  .footer__social-info {
    padding-top: 28px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &__copyright {
      font-family: $font-family-1;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      color: $color-neutral-3
    }

    &__icon-wrapper {
      display: flex;
      align-items: center;
      gap: 22px
    }

    &__icon {
      width: 20px;
      height: 20px;
    }
  }
}

@media only screen and (max-width: $breakpoint-md) {
  .footer {
    nav {
      flex-direction: column;
      gap: 50px;
      align-items: center;
    }
  }
}